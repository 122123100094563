import config from "../../config/config";
import axios from "axios";



const loginService = {

  async signIn(credentials: any) {
    try {
      return await axios.post(`${config.BACKEND_API_URL}/login`, credentials, {
        headers: {
          'Access-Control-Allow-Origin': config.BACKEND_API_URL,
          'Content-Type': 'application/json',
        },
      });
    } catch (error) {
      console.error("ERROR =", error);
      throw error;
    }
  },
  async refreshToken(refreshToken: any) {
    try {
      let response = await axios.post(`${config.BACKEND_API_URL}/token/refresh`, {}, {
        headers: {
          'Access-Control-Allow-Origin': config.BACKEND_API_URL,
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${refreshToken}`,
        },
      });

      return response.data.access_token;
    } catch (error) {
      console.error("ERROR =", error);
      throw error;
    }
  }

}

export default loginService
