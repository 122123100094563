import { jwtDecode } from "jwt-decode";
import moment from 'moment';
import 'moment/locale/es';

interface UserTokenJwt {
  fresh: boolean,
  jti: string,
  type: string,
  sub: string,
  nbf: number,
  csrf: string,
  exp: number
}

const verifyTokenExpiration = async (token: any) => {
  try {
    let decoded = jwtDecode(token) as UserTokenJwt | null;
    const currentTime = Math.floor(Date.now() / 1000);

    if (decoded) {
      return decoded.exp < currentTime;
    }
  } catch (error) {
    console.error('Error al decodificar el token:', error);
  }

  return false;
};

const capitalize = (s: string) => s.charAt(0).toUpperCase() + s.slice(1);

const formatDatetimeFriendly = (timestamp: string) => {
  let formattedTimestamp;

  if (timestamp.length === 14) { // Formato YYYYMMDDHHMMSS
    formattedTimestamp = `${timestamp.slice(0, 4)}-${timestamp.slice(4, 6)}-${timestamp.slice(6, 8)}T${timestamp.slice(8, 10)}:${timestamp.slice(10, 12)}:${timestamp.slice(12, 14)}`;
  } else if (timestamp.length === 8) { // Formato YYYYMMDD
    formattedTimestamp = `${timestamp.slice(0, 4)}-${timestamp.slice(4, 6)}-${timestamp.slice(6, 8)}`;
  } else {
    formattedTimestamp = timestamp;
  }

  const current = new Date();
  const date = new Date(formattedTimestamp);


  const currentDateMidnight = new Date(current.getFullYear(), current.getMonth(), current.getDate());
  const dateMidnight = new Date(date.getFullYear(), date.getMonth(), date.getDate());

  const diffTime = currentDateMidnight.getTime() - dateMidnight.getTime();
  const diffDays = diffTime / (1000 * 60 * 60 * 24);

  const optionsHours: Intl.DateTimeFormatOptions = { hour: '2-digit', minute: '2-digit' };
  const optionsDate: Intl.DateTimeFormatOptions = { month: '2-digit', day: '2-digit' };

  if (diffDays === 0) {
    return date.toLocaleTimeString('es', optionsHours);
  }

  if (diffDays === 1) {
    return 'Ayer';
  }

  if (diffDays > 1 && diffDays <= 5) {
    const dayOfWeek = Intl.DateTimeFormat('es', { weekday: 'long' }).format(date);
    return capitalize(dayOfWeek);
  }

  return date.toLocaleDateString('es', optionsDate);
}

function truncateString(str: string | undefined, maxLength: number): string
 { if (str === undefined) { return ""; } 
   if (str.length > maxLength) { return str.substring(0, maxLength) + "..."; } 
   return str; 
  
}



function parseTimestamp(timestamp: string) {
  moment.locale('es');
  const datetime = moment(timestamp, 'YYYY-MM-DD HH:mm');
  const now = moment();

  let day;
  if (datetime.isSame(now, 'day')) {
    day = 'Hoy';
  } else {
    day = datetime.format('dddd'); // Nombre del día de la semana
  }

  return {
    date: datetime.format('DD/MM/YYYY'),
    time: datetime.format('hh:mm A'), // Hora en formato de 12 horas
    day,
  };
}

export {
  verifyTokenExpiration,
  capitalize,
  formatDatetimeFriendly,
  truncateString,
  parseTimestamp
};