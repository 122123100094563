import React, { useEffect, useState } from 'react';
import { io, Socket } from 'socket.io-client';
import config from '../../config/config'
const useSocketWithEnv = () => {
    const [socket, setSocket] = useState<Socket | null>(null);
    const [mensajes, setMensajes] = useState<any>("");
    useEffect(() => {
        const socketUrl = config.BACKEND_API_URL;
        const serviceAvailable =  config.SERVICE_AVAILABLE;

        if (serviceAvailable === 'true' && socketUrl) {
            const connectSocket = async () => {
                const newSocket = io(socketUrl, {
                    transports: ["websocket"],
                    secure: true, 
                });
                newSocket.on('connect', () => {
                    console.log('connected');
                });

                newSocket.on('nuevos_mensajes', (response) => {
                    setMensajes(response);
                });

                setSocket(newSocket);
                return () => {
                    newSocket.close();
                };
            };

            connectSocket();
        }
    }, []);

    const updateEndpoints = (event: string, data: any) => {
        if (socket) {
            socket.emit(event, data);
        }
    };
    return { socket, updateEndpoints, mensajes };
};

export default useSocketWithEnv;