import { useEffect, useState } from 'react';

const useDetectSameUrlTabs = () => {
  const [tabIde] = useState(() => Math.random().toString(36).substring(2, 15));

  useEffect(() => {
    const channel = new BroadcastChannel('url_check_channel');

    const getTabIds = () => {
      const tabIds = localStorage.getItem('tabIds');
      return tabIds ? JSON.parse(tabIds) : [];
    };

    const addTabId = (id:any) => {
      const tabIds = getTabIds();
      if (!tabIds.includes(id)) {
        tabIds.push(id);
        localStorage.setItem('tabIds', JSON.stringify(tabIds));
      }
    };

    const removeTabId = (id:any) => {
      const tabIds = getTabIds();
      const updatedTabIds = tabIds.filter((tabIde:any)=> tabIde !== id);
      localStorage.setItem('tabIds', JSON.stringify(updatedTabIds));
    };

    const updateTabCount = () => {
      const tabIds = getTabIds();
      console.log(`Pestañas activas: ${tabIds.length}`);
    };

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        if (!getTabIds().includes(tabIde)) {
          addTabId(tabIde);
          updateTabCount();
        }
        channel.postMessage({ url: window.location.href, tabIde });
      }
    };

    const handleMessage = (event:any) => {
      if (event.data.url === window.location.href && !getTabIds().includes(event.data.tabIde)) {
        addTabId(event.data.tabIde);
        updateTabCount();
      }
    };

    // Añadir el ID de esta pestaña al cargar el hook, si aún no está en la lista
    if (!getTabIds().includes(tabIde)) {
      addTabId(tabIde);
      updateTabCount();
    }

    document.addEventListener('visibilitychange', handleVisibilityChange);
    channel.addEventListener('message', handleMessage);

    // Limpiar al cerrar la pestaña
    window.addEventListener('beforeunload', () => removeTabId(tabIde));

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      channel.removeEventListener('message', handleMessage);
      channel.close();
      removeTabId(tabIde); // Asegurarse de limpiar al desmontar
    };
  }, [tabIde]);

  return { tabIde };
  // El hook no necesita retornar nada a menos que quieras exponer datos o métodos
};

export default useDetectSameUrlTabs;