import { useState, useEffect, useCallback } from 'react';
import useDetectSameUrlTabs from '../utils/broadCastChannelTab';

const useWindowsTabActivity = (timeout:any = 1000) => {
    const [isActive, setIsActive] = useState(true);
    const { tabIde } = useDetectSameUrlTabs();
    const [arrayIdes, setArrayIdes] = useState<string[]>([]);
   
    useEffect(() => {
        const fetchData = async () => {
            setArrayIdes(JSON.parse(await localStorage.getItem('tabIds') || '[]'));
        };
        fetchData();
    }, [localStorage.getItem('tabIds')]);

    const activateTab = useCallback(() => {
        setArrayIdes(JSON.parse(localStorage.getItem('tabIds') || '[]'));
        setArrayIdes((currentArrayIdes:any) => {
            const idesArray = currentArrayIdes;
            const index = idesArray.findIndex((currentId:any) => currentId === tabIde);
            if (index > -1) {
                idesArray.splice(index, 1);
                idesArray.unshift(tabIde);
            }
            localStorage.setItem('tabIds', JSON.stringify(idesArray));
            return idesArray;
        });
        setIsActive(true);
    }, []);

    const resetInactivityTimer = () => {
        clearTimeout(timeout);
        timeout = setTimeout(handleInactivity, 1000);
    };

    const handleInactivity = async () =>  {
        if(JSON.parse(await localStorage.getItem('tabIds') || '[]').length === 1){
            return;
        }
        if(JSON.parse(await localStorage.getItem('tabIds') || '[]')[0] === tabIde){
            setIsActive(true);
            return;
        }
        setIsActive(false);
    };
    useEffect(() => {
        const handleVisibilityChange = async () => {
            if(JSON.parse(await localStorage.getItem('tabIds') || '[]').length === 1){
                return;
            }
            if(JSON.parse(await localStorage.getItem('tabIds') || '[]')[0] === undefined){
                return;
            }
            if(JSON.parse(await localStorage.getItem('tabIds') || '[]')[0] === tabIde){
                setIsActive(true);
                console.log("activado");
                return;
            }
            if (document.visibilityState != 'visible') {
                setIsActive(false);
            }
        };
        const identityTab = async () => {
            if(JSON.parse(await localStorage.getItem('tabIds') || '[]').length === 1){
                setIsActive(true);
                return;
            }
            if(JSON.parse(await localStorage.getItem('tabIds') || '[]')[0] === undefined){
                return;
            }
            if(JSON.parse(await localStorage.getItem('tabIds') || '[]')[0] === tabIde){
                setIsActive(true);
                return;
            }
            setIsActive(false);
        }
        identityTab();
        resetInactivityTimer();
        document.addEventListener('visibilitychange', handleVisibilityChange);
        window.addEventListener('mousemove', resetInactivityTimer);
        window.addEventListener('keydown', resetInactivityTimer);
        window.addEventListener('scroll', resetInactivityTimer);
        document.addEventListener('blur', () => handleVisibilityChange);
        document.addEventListener('focus', () => handleVisibilityChange);
        return () => {
            clearTimeout(timeout);
            document.removeEventListener('visibilitychange', handleVisibilityChange);
            window.removeEventListener('mousemove', resetInactivityTimer);
            window.removeEventListener('keydown', resetInactivityTimer);
            window.removeEventListener('scroll', resetInactivityTimer);
            document.addEventListener('blur', handleVisibilityChange);
            document.addEventListener('focus', handleVisibilityChange);
        };
    }, [activateTab, isActive]);

    return { isActive, activateTab };
};
export default useWindowsTabActivity;