import {PayloadAction, createSlice} from "@reduxjs/toolkit"

export const iFilterSlice = createSlice({
  name : "iFilter",
  initialState : {
    value: "all"
  },
  reducers:{
    fillIFilter: (state, action: PayloadAction<string>) => {
      state.value = action.payload
    },
    resetIFilter : (state) =>{
      state.value = ""
    }
  }
})


export const {
  fillIFilter,
  resetIFilter,
} = iFilterSlice.actions;

export default iFilterSlice.reducer;
