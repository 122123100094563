import React, { useState, useEffect, useCallback } from "react";
import ChatContent from "../components/chat/ChatContent";

import { useAppDispatch, useAppSelector } from "../redux/hooks"
import useSocketWithEnv from "../services/socketio/socketIO";
import { resetLoading, toggleLoadingChatList } from "../redux/features/loadingChatList";
import { fillInfoPagination } from "../redux/features/infoPaginationSlice";

import { set } from "../redux/features/newMessageSlice"
import Loading from "../components/shared/Loading";

import historicalService from "../services/historical/historicalService";
import { sha256 } from 'js-sha256';
import DashboardYa from "../template/DashboardYa";
import { resetCurrentPage } from "../redux/features/currentPageSlice";
import { fillPhoneContact } from "../redux/features/phoneContactSlice";
import PreviewImage from "../components/shared/preview/previewImage";
import conversationsOptions from "../utils/conversationsOptions";
import TabIdentific from '../utils/tabIdentific';
import IdleJs from 'idle-js';
import config from '../config/config'

export default function HistoricalPage() {
  const [chatData, setChatData] = useState(""); // Adjust the initial state as needed
  const [pausedNumber, setPausedNumber] = useState([]);
  const [newMessages, setNewMessages] = useState("");
  const [loading, setLoading] = useState(true);
  const { isActive, activateTab } = TabIdentific();
  const [userActive, setUserActive] = useState(false);
  const [isTimeOff, setIsTimeOff] = useState(true);
  const dispatch = useAppDispatch();

  const [dataHash, setDataHash] = useState('');
  const [stoppedMessageHash, setStoppedMessages] = useState('');
  const [notReadMessageHash, setNotReadMessageHash] = useState('');
  const [debounceTimer, setDebounceTimer] = useState<any>(null);

  const perPage = useAppSelector(state => state.perPageReducer.value);
  const currentPage = useAppSelector((state: any) => state.currentPageReducer.value);
  const hotnessBy = useAppSelector(state => state.hotnessByReducer.value);
  const sortBy = useAppSelector(state => state.sortByReducer.value);
  const filterBy = useAppSelector(state => state.filterByReducer.value);
  const dateFilterBy = useAppSelector(state => state.dateFilterByReducer.value);
  const qSearch = useAppSelector(state => state.qSearchReducer.value);
  const iFilter = useAppSelector(state => state.iFilterReducer.value);

  const { mensajes } = useSocketWithEnv();

  const updateChatData = async () => {
    let hServiceOptions: conversationsOptions = {
      currentPage: currentPage,
      perPage: Number.parseInt(perPage),
      cTemperature: hotnessBy,
      sortBy: sortBy,
      filterBy: filterBy,
      dateFilterBy: dateFilterBy,
      qSearch: qSearch,
      iFilter: iFilter,
    };
    const res = await historicalService.getConversations(hServiceOptions);

    if (res.status === 200) {
      const newData = res.data.conversations;
      const newHash = sha256(JSON.stringify(newData));

      const newStoppedMessageHash = sha256(JSON.stringify(res.data.messages_stopped))
      const newNotReadMessageHash = sha256(JSON.stringify(res.data.num_conv))

      if (newHash !== dataHash) {
        setChatData(res.data.conversations);
        setLoading(false);

        dispatch(
          fillInfoPagination({
            currentPage: res.data.current_page,
            perPage: res.data.per_page,
            isLastPage: res.data.is_last_page,
            nextPage: res.data.next_page,
            totalConversations: res.data.total_conversations,
            totalPages: res.data.total_pages,
          })
        );
        dispatch(
          fillPhoneContact(res.data.dict_phones)
        );

        setDataHash(newHash);
      }

      if (newStoppedMessageHash !== stoppedMessageHash) {
        setPausedNumber(res.data.messages_stopped);

        setStoppedMessages(newStoppedMessageHash);
      }

      if (newNotReadMessageHash !== notReadMessageHash) {
        dispatch(set(res.data.num_conv as any));
        setNewMessages(res.data.num_conv);

        setNotReadMessageHash(newNotReadMessageHash);
      } else {
        dispatch(set(res.data.num_conv as any));
        setNewMessages(res.data.num_conv);
      }

    }
  };

  const useChatData = () => {
    const [isActives, setIsActive] = useState(false);
    useEffect(() => {
      let timerId: any;
        if (config.SERVICE_AVAILABLE === 'true') {
          timerId = setTimeout(() => {
            updateChatData()
          }, 5000);
        }else{
          timerId = setInterval(updateChatData, 10000);
        }
      return () => clearTimeout(timerId);
    }, [mensajes, isActives, perPage, currentPage, hotnessBy, sortBy, filterBy, dateFilterBy, qSearch, iFilter, dataHash, stoppedMessageHash, notReadMessageHash]);
    const startInterval = () => setIsActive(true);
    const stopInterval = () => setIsActive(false);
    return { chatData, startInterval, stopInterval, isActives };
  };

  let { startInterval, stopInterval, isActives } = useChatData();

 /* useEffect(() => {
    if (isActive) {
      startInterval()
      setIsTimeOff(true)
    } else {
      stopInterval()
      setIsTimeOff(false)
    }
  }, [isActive]);*/

  useEffect(() => {
    if (currentPage !== 1) {
      dispatch(resetCurrentPage());
    }
  }, [filterBy]);

  useEffect(() => {
    dispatch(toggleLoadingChatList(true));
    updateChatData().then(r => dispatch(resetLoading()));
  }, [perPage, currentPage, hotnessBy, sortBy, filterBy, dateFilterBy, iFilter]);

  useEffect(() => {
    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }

    const newTimer = setTimeout(() => {
      dispatch(toggleLoadingChatList(true));

      updateChatData().then(r => {
        dispatch(resetLoading());
        if (currentPage !== 1) {
          dispatch(resetCurrentPage());
        }
      });
    }, 1500);

    setDebounceTimer(newTimer);

    return () => clearTimeout(newTimer);
  }, [qSearch]);

  useEffect(() => {
    const idle = new IdleJs({
      idle: 500000,
      onIdle: () => {
        if (isTimeOff) {
          stopInterval()
          setUserActive(false)
        }
      },
      onActive: () => {
        startInterval()
        setUserActive(true)
      },
      keepTracking: true,
      startAtIdle: false
    });

    idle.start();

    return () => {
      idle.stop();
    };
  }, []);

  if (loading) {
    return (
      <div>
        <Loading />
      </div>
    )
  }

  return (
    <DashboardYa>
      {(true) ?
        <div>
          <PreviewImage />
          {chatData && pausedNumber && <ChatContent chatData={chatData} loading={loading} pausedNumber={pausedNumber} />}
        </div>
        :
        <div className="flex flex-col tab-yavendio">
          <div className="flex flex-col yavendio-alerta">
            <h1>YaVendió está abierto en otra ventana. Haz click en "Usar aquí" para abril YaVendió en esta ventana</h1>
            <button className="btn btn-primary mt-4" onClick={activateTab}>{isActive ? "Activo" : "Usar aquí"}</button>
          </div>
        </div>
      }
    </DashboardYa>
  );
}
