import { PayloadAction, createSlice } from "@reduxjs/toolkit"

export const imagenPreviewModal = createSlice({
    name: "imagenPreviewModal",
    initialState: {
        value: false,
        src: '',
    },
    reducers: {
        changeModal: (state, action: PayloadAction<boolean>) => {
            state.value = action.payload
        },
        uploadImage: (state, action: PayloadAction<string>) => {
            state.src = action.payload
        },
        resetModal: (state) => {
            state.value = false
            state.src = ''
        }
    }
})


export const {
    changeModal,
    uploadImage,
    resetModal
} = imagenPreviewModal.actions;

export default imagenPreviewModal.reducer;
