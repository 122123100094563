import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { resetModal } from "../../../redux/features/previewImagen";
import { TransformWrapper, TransformComponent, useControls } from "react-zoom-pan-pinch";
import { ZoomIn, ZoomOut, Cross } from "akar-icons";
const Controls = () => {
    const { zoomIn, zoomOut, resetTransform } = useControls();

    return (
        <div className="tools">
            <button title='Acercarse' onClick={() => zoomIn()}><ZoomIn size={20} className='icon-preview'/></button>
            <button title='Alejarse' onClick={() => zoomOut()}><ZoomOut size={20} className='icon-preview' /></button>
        </div>
    );
};

export default function PreviewImage() {
    const dispatch = useDispatch();
    const open = useSelector((state: any) => state.previewImagen.value);
    const image = useSelector((state: any) => state.previewImagen.src);

    const resetarModal = () => {
        dispatch(resetModal());
    }

    React.useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                resetarModal();
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    return (
        <div className={open ? "preview-image" : "preview-image-off"}>

            <TransformWrapper
                initialScale={1}
                initialPositionX={200}
                initialPositionY={100}
            >
                {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                    <>
                        <button className='close-preview' onClick={() => resetarModal()}><Cross className='icon-preview' size={20}></Cross></button>
                        <TransformComponent>
                            <img src={image} className='img-preview' alt={'Preview'} />
                        </TransformComponent>
                        <Controls />
                    </>
                )}
            </TransformWrapper>
        </div>
    );
}